export const getCanonicalURLs = (pageData) => {
  const canonicals = [];
  if (pageData) {
    for (let index = 0; index < pageData.length; index++) {
      canonicals.push({
        'href': pageData[index].href,
        'rel': 'canonical',
      });
    }
  }
  return canonicals;
};

export const migratedPartners = ['vodacomnew', 'straighttalk1', 'mcdonalds',
  'simplemobile', 'totalrewards', 'tracfone', 'walmart', 'paytm',
  'paytmfirst', 'flipkartplus', 'flipkart',
  'airtel', 'airtelthanks', 'snagajob1', 'bayt', 'nedbank'];

export const handleScroll = () => {
  const images = document.getElementsByTagName('img');
  Array.from(images).forEach((img) => {
    if (img.dataset.src && img.src.indexOf(img.dataset.src) <= -1) {
      img.src = img.dataset.src;
    }
  });
};
