/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
/**
  * Returns an helmet object
  *
  * @param {string} description
  * @param {string} lang
  * @param {string} meta
  * @param {string} title
  * @param {string} image
  * @param {string} isCoursePage
  * @return {object} Helmet Object
  */
function SEO({
  description,
  locale,
  meta,
  title,
  image,
  canonicalUrls,
  languageCanonicals,
  schemaMarkup,
  isCoursePage=false,
}) {
  const siteUrl='https://www.shawacademy.com';
  if (languageCanonicals && languageCanonicals.edges && !isCoursePage) {
    if (!canonicalUrls) {
      canonicalUrls=[];
    }
    const edges=languageCanonicals.edges;
    for (let index=0; index<edges.length; index++) {
      const node=edges[index].node;
      const lang=node.publish_details.locale;
      const url=node.url;
      if (lang=='en') {
        canonicalUrls.push({
          'href': siteUrl+url,
          'rel': 'alternate',
          'hreflang': 'x-default',
        });
        canonicalUrls.push({
          'href': siteUrl+url,
          'rel': 'alternate',
          'hreflang': 'en',
        });
      } else {
        canonicalUrls.push({
          'href': siteUrl+'/'+lang+url,
          'rel': 'alternate',
          'hreflang': lang,
        });
      }
    }
  }
  const siteMetadata= {
    description: '',
    siteUrl: siteUrl,
    title: 'Shaw Academy',
  };
  const metaDescription = description || siteMetadata.description;

  const url = siteMetadata.siteUrl;
  const ogImage = `${image}` || `${url}/assets/img/cover.png`;

  // add rtl support - Arabic is Right to Left Language
  // change ar with your RTL language
  const dir = locale === 'ar' ? 'rtl' : 'ltr';
  const stagingDomain = Number(process.env.StagingDomain);
  return (
    <Helmet
      htmlAttributes={{
        // fix html lang attribute
        lang: locale||'en',
        dir,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      link={canonicalUrls}

      meta={[
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image:src`,
          content: ogImage,
        },
        {
          name: `twitter:creator`,
          content: 'shawacademy',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <meta charSet="utf-8" />
      <meta name="description" content={metaDescription}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={ogImage}/>
      <meta property="og:type" content="website"/>
      {schemaMarkup &&
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}
      </script>}
      {stagingDomain&&<meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  isCoursePage: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  locale: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  canonicalUrls: PropTypes.arrayOf(PropTypes.object),
  languageCanonicals: PropTypes.object,
  schemaMarkup: PropTypes.any,
  isCoursePage: PropTypes.bool,
};

export default SEO;
