import React, {useEffect} from 'react';
import Header from '../Templates/Shared/Header';
import styles from '../Templates/Styles/staticpage.module.scss';
import htmr from 'htmr';
import contactPageData from '../../data/staticPages/contactPage.json';
import Footer from '../Templates/Shared/Footer';
import Helmet from 'react-helmet';
import SEO from '../Templates/SEO';
import schemaMarkup from '../../static/schema.json';
import {getCanonicalURLs, migratedPartners, handleScroll} from
  '../Templates/Shared/StaticPageService';
import {getParameterByName} from '../Templates/Shared/Utils';
import cookie from 'react-cookies';

const Contact = () => {
  const locale = 'en';
  const canonicals = getCanonicalURLs(contactPageData.seo.canonicalurls);

  useEffect(() => {
    const utmSource = cookie.load(
        'utm_source') || getParameterByName('utm_source');
    const isMigratedPartner = migratedPartners.includes(utmSource);
    const id = document.getElementById('signupcta');
    if (isMigratedPartner) {
      if (id) {
        id.href = `https://learning.upskillist.com/${utmSource}`;
      }
    } else {
      if (id) {
        if (utmSource?.toLowerCase()?.startsWith('sc') ||
          utmSource?.toLowerCase()?.startsWith('stack')) {
          id.href = `https://learning.upskillist.com/stackcommerce`;
        } else {
          id.href = `https://learning.upskillist.com/?utm_source=${utmSource}&utm_medium=partners`;
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <SEO
        locale={locale}
        title={contactPageData.seo.title}
        description={contactPageData.seo.description}
        image={''}
        canonicalUrls={canonicals}
        languageCanonicals={contactPageData.languageCanonicals}
        schemaMarkup={schemaMarkup}
      />
      <Helmet>
        <script>
          window.dataLayer=window.dataLayer||[];
        </script>
      </Helmet>
      <Header locale={locale} />
      <main className={`contact container ${styles.staticPage}`}>
        {contactPageData.sections.map((section) => {
          let content = '<div></div>';
          if (section.pagesection) {
            content = section.pagesection.content;
          }
          return (htmr(content));
        })}
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
